// button
$btn-sizes: (
  "x-small": 20,
  "small": 28,
  "default": 36,
  "large": 44,
  "x-large": 52,
);
$btn-border-radius: 8px;
$btn-text-transform: capitalize;

// tooltip
$tooltip-opacity: 0.95;
$tooltip-font-size: 12px;
